import React from 'react';
import { Control, actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getFormData,
  getFormMetaController,
} from '../../../selectors/formSelector';
import FormApi from '../../../api/formApi';
import * as logtypes from '../../../common/logtypes';
import { ltLogger, reportFieldEngagement } from '../../../bootstrap';
import { currencyFormatter } from '../../../utils/formatters';
import DataLoader from '../../common/DataLoader';
/*eslint-disable react/no-multi-comp,react/no-danger*/

const SelectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  delete inputProps.touched;
  delete inputProps.modelValue;

  const isSelected =
    props.autoFocus &&
    (props.submitted || (props.modelValue === props.htmlFor && props.touched));
  let selectedClass = isSelected ? 'selected' : '';
  let classNames = `trimLabel ${selectedClass}`;

  return (
    <label htmlFor={props.htmlFor} tabIndex="0" className={classNames}>
      <input {...inputProps} />
      {props.children}
    </label>
  );
};

SelectedWrapper.propTypes = {
  htmlFor: PropTypes.number,
  autoFocus: PropTypes.bool,
  submitted: PropTypes.bool,
  children: PropTypes.array,
  modelValue: PropTypes.number,
  touched: PropTypes.bool,
};

class VehicleTrim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleTrims: [],
      isDataLoaded: false,
    };
  }

  componentDidMount() {
    if (this.props.formData) {
      let selectedModelId = this.props.formData.VehicleModelId;
      this.fetchVehicleTrims(selectedModelId);
    }
  }

  fetchVehicleTrims = (selectedModelId) => {
    let currentComponent = this;
    currentComponent.setState({ isDataLoaded: false });

    return FormApi.fetchVehicleTrims(selectedModelId, false)
      .then((response) => {
        if (response && response.response && response.response.error) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: new Error(response.response.error),
          });
          return null;
        }
        let trimCollection = response !== undefined ? response : [];
        currentComponent.setState({
          vehicleTrims: trimCollection,
          isDataLoaded: true,
        });
      })
      .catch((error) => {
        ltLogger(logtypes.ERROR, undefined, {}, undefined, { error: error });
      });
  };

  translateTrim = (trimObject) => {
    this.props.dispatch(
      actions.change(`formData.ChromeStyleId`, trimObject.trimId.toString())
    );
    this.props.dispatch(
      actions.change(`formData.VehicleTrim`, trimObject.trimName)
    );
    this.props.dispatch(
      actions.change(
        `formData.ChromeFriendlyStyleName`,
        trimObject.friendlyTrimName
      )
    );

    return FormApi.translateTrim(trimObject.trimId)
      .then((response) => {
        if (response && response.response && response.response.error) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: new Error(response.response.error),
          });
          return null;
        }
        let kbbTrimId = response !== undefined ? response.KbbTrimId : undefined;
        this.props.dispatch(actions.change(`formData.KBBTrimId`, kbbTrimId));
        if (this.props.formMeta.AutomobileLoanSubType === 'NewCarPurchase') {
          this.props.dispatch(
            actions.change(
              `formData.VehicleMSRP`,
              trimObject.evaluation.msrp.toString()
            )
          );
          this.props.dispatch(
            actions.change(
              `formData.VehicleInvoicePrice`,
              trimObject.evaluation.invoice.toString()
            )
          );
          this.props.handleNext();
        }
      })
      .catch((error) => {
        ltLogger(logtypes.ERROR, undefined, {}, undefined, { error: error });
      });
  };

  getPrices = (trimObject) => {
    return FormApi.getPrices(trimObject.trimId)
      .then((response) => {
        if (response && response.response && response.response.error) {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: new Error(response.response.error),
          });
          return null;
        }
        let price = response !== undefined ? response.msrp : 0;
        this.props.dispatch(
          actions.change(`formData.VehicleWholesalePrice`, price.toString())
        );
        this.props.handleNext();
      })
      .catch((error) => {
        ltLogger(logtypes.ERROR, undefined, {}, undefined, { error: error });
      });
  };

  render() {
    const { isDataLoaded } = this.state;
    let baseTrim = this.state.vehicleTrims[0];
    return (
      <div>
        {!isDataLoaded ? (
          <DataLoader />
        ) : (
          <div className="row">
            <div className="vehicleColMd6">
              <div className="vehicleTrimName">
                {this.state.vehicleTrims !== undefined &&
                this.state.vehicleTrims.length > 0
                  ? this.state.vehicleTrims[0].model.modelName
                  : ''}
              </div>
              {window.LT5theme !== true &&
                (this.state.vehicleTrims[0].imageUrl === undefined ? (
                  <div className="vehicleDefaultTrim" />
                ) : (
                  <img
                    src={this.state.vehicleTrims[0].imageUrl}
                    className="vehicleTrimModel"
                    alt=""
                    aria-hidden="true"
                  />
                ))}
            </div>
            <div className="vehicleColMd6">
              <div className="vehicleTrim">Trim</div>
              <div className="msrpHeader">
                {' '}
                {this.state.vehicleTrims &&
                this.state.vehicleTrims.length > 0 &&
                this.props.formMeta.AutomobileLoanSubType ===
                  'NewCarPurchase' &&
                this.state.vehicleTrims[0].evaluation.msrp !== 0
                  ? 'MSRP starting at'
                  : ''}
              </div>
              <div className="vehicleTrimScrollbar">
                {this.state.vehicleTrims.map((item) => (
                  <div key={item.trimId} className="ltFormRadioControl">
                    <Control.radio
                      name={item.trimName}
                      id={item.trimId}
                      model={`formData.VehicleTrimId`}
                      className={`ltFormRadioButton ${item.trimName}`}
                      component={SelectedWrapper}
                      value={item.trimId}
                      changeAction={(model, value, event) => (dispatch) => {
                        if (event && value !== event.currentValue) {
                          return;
                        }
                        reportFieldEngagement('VehicleTrim', value);
                        dispatch(actions.change(model, value));
                        if (
                          event &&
                          this.props.steps.length === 1 &&
                          event.currentValue === value
                        ) {
                          this.translateTrim(item);
                          if (
                            this.props.formMeta.AutomobileLoanSubType !==
                            'NewCarPurchase'
                          ) {
                            this.getPrices(item);
                          }
                        }
                      }}
                      mapProps={{
                        autoFocus: (props) => props.modelValue === item.trimId,
                        htmlFor: item.trimId,
                        submitted: ({ fieldValue }) => fieldValue.submitted,
                        modelValue: (props) => props.modelValue,
                        touched: ({ fieldValue }) => fieldValue.touched,
                        onClick: (props) => () => {
                          if (props.value === props.controlProps.value) {
                            props.dispatch(actions.setTouched(props.model));
                            props.dispatch(
                              props.changeAction(props.model, props.value, {
                                currentValue: props.controlProps.value,
                              })
                            );
                          }
                        },
                      }}
                    >
                      <div
                        className="trimName"
                        dangerouslySetInnerHTML={{ __html: item.trimName }}
                      />
                      <div>
                        {this.props.formMeta.AutomobileLoanSubType ===
                          'NewCarPurchase' && item.evaluation.msrp !== 0 ? (
                          <div className="msrpText">
                            {currencyFormatter(item.evaluation.msrp)}
                          </div>
                        ) : null}
                        <div
                          className={`${
                            this.props.formMeta.AutomobileLoanSubType ===
                            'NewCarPurchase'
                              ? 'rightArrow'
                              : 'rightArrowWithoutMsrp'
                          }`}
                        />
                      </div>
                    </Control.radio>
                  </div>
                ))}
              </div>
              <div>
                {
                  <Control.button
                    name={baseTrim.trimName}
                    id={baseTrim.trimId}
                    model={`formData.VehicleTrimId`}
                    className={`ltFormIDontKnowLink ${baseTrim.trimName}`}
                    value={baseTrim.trimId}
                    changeAction={(model, value, event) => (dispatch) => {
                      if (event && value !== event.currentValue) {
                        return;
                      }
                      dispatch(actions.change(model, value));
                      if (
                        event &&
                        this.props.steps.length === 1 &&
                        event.currentValue === value
                      ) {
                        this.translateTrim(baseTrim);
                      }
                    }}
                    mapProps={{
                      onClick: (props) => () => {
                        if (props.value === props.controlProps.value) {
                          props.dispatch(actions.setTouched(props.model));
                          props.dispatch(
                            props.changeAction(props.model, props.value, {
                              currentValue: props.controlProps.value,
                            })
                          );
                        }
                      },
                    }}
                  >
                    <div>I don’t know</div>
                  </Control.button>
                }
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

/*eslint-enable */
VehicleTrim.propTypes = {
  step: PropTypes.object,
  steps: PropTypes.array,
  handleNext: PropTypes.func,
  fieldValue: PropTypes.object,
  modelValue: PropTypes.object,
  controlProps: PropTypes.object,
  viewValue: PropTypes.string,
  value: PropTypes.string,
  dispatch: PropTypes.func,
  model: PropTypes.object,
  changeAction: PropTypes.func,
  formData: PropTypes.object,
  formMeta: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    formData: getFormData(state),
    formMeta: getFormMetaController(state),
  };
}

export default connect(mapStateToProps)(VehicleTrim);

import React from 'react';
import { Provider } from 'react-redux';
import { getStore } from '../../store';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import SlcDisclosureCheckboxes from './slcDisclosureCheckboxes';

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
  }
  // This example assumes the direct mounting method as a workaround
  componentDidMount() {
    const placeholder = window.ltPhoenix?.rootElement?.querySelector(
      '#slc-lender-list-placeholder'
    );
    if (placeholder) {
      const ComponentWithProvider = (
        <Provider store={getStore()}>
          <SlcDisclosureCheckboxes SLCLenderList={this.props.SLCLenderList} />
        </Provider>
      );
      ReactDOM.render(ComponentWithProvider, placeholder);
    }

    let { markup } = this.props;
    let extractscript = /<script>([\s\S]*)<\/script>/gi.exec(markup);
    if (extractscript !== null) {
      markup = markup.replace(extractscript[0], '');
      new Function(extractscript[1])();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.markup !== nextProps.markup) {
      let { markup } = nextProps;
      let extractscript = /<script>([\s\S]*)<\/script>/gi.exec(markup);
      if (extractscript !== null) {
        markup = markup.replace(extractscript[0], '');
        new Function(extractscript[1])();
      }
    }
  }

  componentWillUnmount() {
    const placeholder = window.ltPhoenix?.rootElement?.querySelector(
      '#slc-lender-list-placeholder'
    );
    if (placeholder) {
      // Unmounting, clean up
      ReactDOM.unmountComponentAtNode(placeholder);
    }
  }

  render() {
    let { markup, additionalClasses = [] } = this.props;
    const classes = `ltFormTermsConditions ${additionalClasses?.join(
      ' '
    )}`.trim();
    const modifiedContent = markup.replace(
      '{SLCLenderListMarkup}',
      '<div id="slc-lender-list-placeholder"></div>'
    );
    return (
      <div
        className={classes} // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: modifiedContent }}
      />
    );
  }
}

TermsAndConditions.propTypes = {
  markup: PropTypes.string,
  additionalClasses: PropTypes.arrayOf(PropTypes.string),
  SLCLenderList: PropTypes.array,
};

export default TermsAndConditions;

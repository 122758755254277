import React from 'react';
import MaskedControl from './react-text-mask';
import ErrorWrapper from '../../common/errorWrapper';
import PropTypes from 'prop-types';
import { reportFieldEngagement } from '../../../bootstrap';

const MaskedTextControl = (props) => {
  let { inputMask, step, hasError } = props;
  const [lastReportedValue, setLastReportedValue] = React.useState('');
  const customPipe = (conformedValue, config) => {
    if (config.rawValue === '') return '';
    let placeholder = config.placeholder.match(/_/g);
    if (
      (placeholder && placeholder.length >= config.rawValue.length) ||
      !config.previousConformedValue
    )
      return config.rawValue;
    return false;
  };

  const handleFieldEngagement = (fieldName, fieldValue, formatterName) => {
    if (fieldValue) {
      let isPopulated =
        fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
      if (formatterName === 'dateFormatter') {
        // PHX-5081 :: INFO / TECH-DEBT
        // This assumes forward slash is the DOB separator,
        // which might not be the case in the future
        let values = fieldValue.split('/');
        const numRegex = new RegExp('^[0-9]+$');
        for (let i = 0; i < values.length; i++) {
          if (!numRegex.test(values[i])) {
            isPopulated = false;
            break;
          }
        }
      } else if (formatterName === 'phoneFormatter') {
        isPopulated = fieldValue.length === 10;
      } else if (fieldName === 'SSN') {
        isPopulated = fieldValue.length === 9;
      } else if (fieldName === 'Last4SSN') {
        isPopulated = fieldValue.length === 4;
      }
      if (isPopulated && fieldValue !== lastReportedValue) {
        reportFieldEngagement(fieldName, fieldValue);
        setLastReportedValue(fieldValue);
      }
    }
  };
  return (
    <ErrorWrapper step={step} hasError={hasError} hasInput={true}>
      <MaskedControl
        mask={inputMask}
        //privacyChar={privacyChar}
        id={props.id}
        name={props.name}
        type={props.type}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        className={props.className}
        placeholder={props.placeholder}
        placeholderChar={props.placeholderChar}
        pipe={Array.isArray(inputMask) && step.mask ? customPipe : undefined}
        //pasteAdjuster={(val) => props.onPasted(val)}
        onKeyPress={(e) => {
          // Zach James :: PHX-5081 :: TODO
          // Unrelated to 5081,
          // We should be calling passing onKeyPress from textControl as a prop and calling it
          // We should do the same thing for onBlur
          if (e.key === 'Enter') {
            let fieldValue = props.value;
            let formatterName =
              props.formatter && props.formatter.name
                ? props.formatter.name
                : undefined;
            handleFieldEngagement(step.attributeid, fieldValue, formatterName);
          }
        }}
        onBlur={(e) => props.onBlur(e)}
        value={props.value ? props.value : ''}
        onFocus={props.onFocus}
        onChange={(e) => {
          props.onChange(e.currentTarget.value);
        }}
      />
    </ErrorWrapper>
  );
};
MaskedTextControl.propTypes = {
  inputMask: PropTypes.array,
  step: PropTypes.object,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderChar: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  formatter: PropTypes.func,
};

export default MaskedTextControl;
